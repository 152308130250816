export const data = [
    {
        id:'fundo-astillero',
        title:'Fundo Astillero',
        type:'Loteo',
        info:'70 sitios de 5.000 m2 y más.',
        entrega:'Sitios urbanizados (Luz y Agua)',
        formaPago:'Solicita mayor información.',
        image:require('../assets/img/proyectos/proyecto1.jpg').default,
        url:'https://www.lanube360.com/fundo-astillero/',
        lineWidth:'60%',
        lineWidthMd:'5%',
        webpay:true,
        webpayUrl:"https://www.webpay.cl/form-pay/122597"
    },
    {
        id:'parque-carelmapu',
        title:'Parque Carelmapu',
        type:'Loteo',
        info:'62 sitios de 5.000 m2 y más.',
        entrega:'Sitios urbanizados (Luz y Agua)',
        formaPago:'Solicita mayor información.',
        image:require('../assets/img/proyectos/proyecto2.jpg').default,
        url:'https://www.lanube360.com/parque-carelmapu/',
        lineWidth:'60%',
        lineWidthMd:'5%',
        webpay:true,
        webkitURL:"https://www.webpay.cl/form-pay/122599"
    },
    {
        id:'hacienda-chacao',
        title:'Hacienda Chacao',
        type:'Loteo',
        info:'74 sitios de 5.000 m2 y más.',
        entrega:'Sitios urbanizados',
        formaPago:'Solicita mayor información.',
        image:require('../assets/img/proyectos/proyecto3.jpg').default,
        url:'https://www.lanube360.com/haciendachacao/',
        lineWidth:'60%',
        lineWidthMd:'5%',
        webpay:true,
        webkitURL:"https://www.webpay.cl/form-pay/131731"
    },
    {
        id:'vertices',
        title:'Vértices',
        type:'Loteo',
        info:'13 sitios de 5.000 m2 y más.',
        entrega:'Sitios urbanizados (Luz y Agua)',
        formaPago:'Solicita mayor información.',
        image:require('../assets/img/proyectos/proyecto4.jpg').default,
        url:'https://www.lanube360.com/vertice/',
        lineWidth:'60%',
        lineWidthMd:'5%',
        webpay:true,
        webkitURL:"https://www.webpay.cl/form-pay/122600"
    }
]
